import * as React from "react"
import '../../style.css'
import PPTCarousel from "../../components/carousel";
import Layout from "../../components/layout"

const Electromagnetic = () => {
    const info ={
        title:"The Electromagnetic Spectrum",
        presenter:'Dr. Phil Plait',
        presentationType:'To Educator Ambassadors',
        folder:'powerpoints/ElectromagneticSpectrum',
        folderLength:21,
        slides:null,
        ppt:'electromagneticSpectrum.ppt',
        pptx:'electromagneticSpectrum.pptx',
        page:'electromagneticSpectrum'
    }

    let slides=[]
    for(let i = 1; i <= info.folderLength; i++){
        slides.push(`/${info.folder}/Slide${i}`);
    }

    return (
        <Layout>
                <h1>{info.title}</h1>
                <h2>{info.presenter}</h2>
                <PPTCarousel slides={slides}/>
                <p>Download the slides as a <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">ppt</a> or <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">pptx</a></p>
        </Layout>
  )
}

export default Electromagnetic